import React, { useEffect, useRef, useState } from "react";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { FormattedMessage } from "react-intl";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { Input, MainButton, FileList, Dropzone } from "../../../../../global";
import { showToast } from "../../../../../global/toast";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { ICemeteryAttachment } from "../../../../../../shared/providers/cemetery/entities/attachments.interface";

interface EditProps {
    doAfterSubmit(subscription: string): void;
    layerInfo?: Record<string, string>;
    info?: Partial<ICemeteryAttachment>;
}

export const EditAttachment: React.FC<EditProps> = ({
    doAfterSubmit,
    layerInfo,
    info,
}) => {
    const formRef = useRef<FormHandles>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [propertyFiles, setPropertyFiles] = useState<string[]>();

    const updateCurrentFiles = (newFiles: string[]) => {
        setPropertyFiles(newFiles);
    };

    const handleSubmit = async ({
        files,
        observation,
        subscription,
    }: {
        observation?: string;
        subscription: string;
        files?: File[];
    }) => {
        if (!subscription) {
            showToast({
                type: "warn",
                message: "Inscrição obrigatória",
            });
            return;
        }

        setLoading(true);

        let newFiles = propertyFiles || [];

        try {
            // caso existam arquivos para realizar upload enviam esses arquivos para api
            // e concatenam as urls com os previos arquivos
            if (files?.length) {
                const uploadedFiles = await CemeteryInfoApiService.uploadFiles(
                    files,
                    subscription
                );

                uploadedFiles.forEach(
                    (uploadedFile: {
                        fileName: string;
                        destination: string;
                    }) => {
                        newFiles = newFiles.concat(uploadedFile.destination);
                    }
                );

                const payload = {
                    observacao: observation,
                    anexos: newFiles,
                    inscricao: subscription,
                };

                await CemeteryInfoApiService.updateAttachmentNames(
                    subscription,
                    payload
                );
                showToast({
                    type: "success",
                    message: "Dados de anexo atualizados com sucesso",
                });
            }
            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setPropertyFiles(info?.anexos);
        formRef.current?.setData({
            observation: info?.observacao,
            subscription: info?.inscricao,
        });
    }, [info]);

    return (
        <div className="flex justify-center items-center">
            <Form
                onSubmit={handleSubmit}
                ref={formRef}
                className="md:w-2/4 space-y-2"
            >
                <Input
                    name="subscription"
                    label="Inscrição"
                    value={layerInfo?.inscricao}
                    disabled
                    hidden
                />
                <Input name="observation" label="Observação" />

                {propertyFiles && propertyFiles.length > 0 && (
                    <FileList
                        files={propertyFiles}
                        canRemove
                        title="Arquivos salvos"
                        doAfterRemoveFromList={updateCurrentFiles}
                    />
                )}
                <Dropzone name="files" />

                <MainButton type="submit" loading={loading}>
                    <FormattedMessage id="save" />
                </MainButton>
            </Form>
        </div>
    );
};
