import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { FormCheck } from "react-bootstrap";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton, Select } from "../../../../../global";
import { Input } from "../../../../../global/input/index";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { DeceasedComponent } from "./components/deceased";
import {
    ICemeteryTransferredExtern,
    ICemeteryTransferredIntern,
} from "../../../../../../shared/providers/cemetery/entities/transferred.interface";
import { transferredInternValidator } from "./components/validatorIntern";
import { yupValidationErrorByField } from "../../../../../../shared/utils/yup-error-by-field";
import { transferredExternValidator } from "./components/validatorExtern";
import { ICemeteryDeceased } from "../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { ControlComponent } from "./components/control";
import { Cities } from "../owner/components/cities";
import { States } from "../owner/components/states";
import { SelectCountryComponent } from "./components/country";

const labelStyle = { fontSize: "15px", color: "#234e52" };

interface AddFormProps {
    doAfterSubmit(subscription: string): void;
    subscription?: string | undefined;
}
export const AddTransferred: React.FC<AddFormProps> = ({
    doAfterSubmit,
    subscription,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [transferType, setTransferType] = useState<
        "TRANSLADO ENVIO" | "TRANSLADO INTERNO"
    >("TRANSLADO INTERNO");

    const handleSubmitTransferredIntern = async ({
        deceasedForm,
        controleForm,
        loteForm,
        quadraForm,
        inscricaoBasedOnControleForm,
    }: {
        deceasedForm: number;
        controleForm: string;
        loteForm: string;
        quadraForm: string;
        inscricaoBasedOnControleForm: string;
    }) => {
        try {
            setLoading(true);
            const deceasedResponse =
                await CemeteryInfoApiService.getDeceasedById(deceasedForm);

            const payload: ICemeteryTransferredIntern = {
                id_falecido: deceasedForm,
                inscricao: subscription || "",
                tipo_sepultamento_anterior: deceasedResponse.tipo_cadastramento,
                lote: loteForm,
                controle: controleForm,
                quadra: quadraForm,
                nome: deceasedResponse.nome,
                tipo_translado: transferType,
                inscricao_falecido: deceasedResponse.inscricao_falecido,
            };
            await transferredInternValidator(payload);

            await CemeteryInfoApiService.createTransferred(payload);

            const emTranslado: Partial<ICemeteryDeceased> = {
                tipo_cadastramento: "TRANSLADO INTERNO",
                inscricao: inscricaoBasedOnControleForm,
                em_translado: true,
            };

            await CemeteryInfoApiService.updateDeceased(
                deceasedResponse.id,
                emTranslado
            );

            showToast({
                type: "success",
                message:
                    "Dados do translado interno foram inseridos com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitTransferredExtern = async ({
        deceasedForm,
        cemiterioForm,
        loteForm,
        quadraForm,
        jazigoForm,
        cidadeForm,
        estadoForm,
        paisForm,
    }: {
        deceasedForm: number;
        cemiterioForm: string;
        loteForm: string;
        quadraForm: string;
        jazigoForm: string;
        cidadeForm: string;
        estadoForm: string;
        paisForm: string;
    }) => {
        try {
            setLoading(true);
            const deceasedResponse =
                await CemeteryInfoApiService.getDeceasedById(deceasedForm);

            const payload: ICemeteryTransferredExtern = {
                id_falecido: deceasedForm,
                inscricao: subscription || "",
                tipo_sepultamento_anterior: deceasedResponse.tipo_cadastramento,
                controle: deceasedResponse.controle,
                lote: loteForm,
                quadra: quadraForm,
                cemiterio_destino: cemiterioForm,
                jazigo: jazigoForm,
                cidade: cidadeForm,
                estado: estadoForm,
                pais: paisForm,
                nome: deceasedResponse.nome,
                tipo_translado: transferType,
                inscricao_falecido: deceasedResponse.inscricao_falecido,
            };
            await transferredExternValidator(payload);

            await CemeteryInfoApiService.createTransferred(payload);

            const transferredExtern: Partial<ICemeteryDeceased> = {
                translado_externo: true,
            };

            await CemeteryInfoApiService.updateDeceased(
                deceasedResponse.id,
                transferredExtern
            );

            showToast({
                type: "success",
                message:
                    "Dados do translado de envio foram inseridos com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    const handleRelatedByChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setTransferType(
            event.target.value as "TRANSLADO ENVIO" | "TRANSLADO INTERNO"
        );
    };

    return (
        <div className="bg-gray-50">
            <p>Adicionar</p>
            {!loading && (
                <div className="flex space-x-2 ml-2">
                    <p style={labelStyle}>Tipo do Cadastro:</p>
                    <FormCheck
                        type="radio"
                        name="serviceTypeTransladoInterno"
                        label="TRANSLADO INTERNO"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="TRANSLADO INTERNO"
                        checked={transferType === "TRANSLADO INTERNO"}
                    />
                    <FormCheck
                        type="radio"
                        name="serviceTypeTranslado"
                        label="TRANSLADO ENVIO"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="TRANSLADO ENVIO"
                        checked={transferType === "TRANSLADO ENVIO"}
                    />
                </div>
            )}
            {subscription && transferType === "TRANSLADO INTERNO" && (
                <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                    <Form
                        onSubmit={handleSubmitTransferredIntern}
                        ref={formRef}
                        className="w-full md:w-6/6"
                    >
                        <div className="grid gap-1 grid-cols-1 grid-rows-1 relative">
                            <DeceasedComponent subscription={subscription} />

                            <ControlComponent />

                            <div className="md:w-2/3 h-10 mt-2">
                                <MainButton type="submit">SALVAR</MainButton>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
            {subscription && transferType === "TRANSLADO ENVIO" && (
                <div className="grid gap-1 grid-cols-1 grid-rows-1 relative">
                    <Form
                        onSubmit={handleSubmitTransferredExtern}
                        ref={formRef}
                        className="w-full md:w-6/6"
                    >
                        <div className="grid gap-1 grid-cols-3 grid-rows-1 relative">
                            <DeceasedComponent subscription={subscription} />

                            <Input
                                name="cemiterioForm"
                                label="CEMITÉRIO DE DESTINO"
                            />

                            <Input name="loteForm" label="LOTE" />

                            <Input name="quadraForm" label="QUADRA" />

                            <Input name="jazigoForm" label="JAZIGO" />

                            <Cities city="" />

                            <States state="" />

                            <Select
                                name="paisForm"
                                label="PAÍS"
                                placeholder="Selecione o país..."
                                options={[
                                    {
                                        label: "BRASIL",
                                        value: "BRASIL",
                                    },
                                ]}
                            />
                        </div>
                        <div className="md:w-2/3 h-10 mt-8">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </Form>
                </div>
            )}
        </div>
    );
};
