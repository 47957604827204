/* eslint-disable react/no-danger */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { IProperty } from "../../../../shared/interfaces/IProperties";
import { HeaderPalmeira } from "./header";

interface Props {
    propertyInfo?: IProperty;
}

export const PalmeiraLeiZoneamento: React.FC<Props> = ({ propertyInfo }) => {
    // const splitted = propertyInfo?.zoneamento?.lei_permissivas
    //     .toUpperCase()
    //     .split(";");

    // const splitted2 = propertyInfo?.zoneamento?.lei_permitidas
    //     .toUpperCase()
    //     .split(";");

    // const splitted3 = propertyInfo?.zoneamento?.lei_proibidas
    //     .toUpperCase()
    //     .split(";");

    // const splitted4 = propertyInfo?.zoneamento?.lei_estacionamento
    //     .toUpperCase()
    //     .split(";");

    // console.log(splitted2);
    return (
        <>
            <p className="font-semibold">
                DESCRIÇÃO DO ZONEAMENTO ZONEAMENTO ► LC 14/2023 | LC23/2022
            </p>
            <Row>
                <table className="table">
                    <tbody>
                        <tr style={{ background: "#D0CECE" }}>
                            <td className="font-semibold">SIGLA</td>
                            <td className="font-semibold">ZONEAMENTO</td>
                            <td className="font-semibold">DESCRIÇÃO</td>
                        </tr>
                        <tr style={{ background: "#E7E6E6" }}>
                            <td>{propertyInfo?.zoneamento?.zona_uso}</td>
                            <td>{propertyInfo?.zoneamento?.zoneamento}</td>
                            <td>{propertyInfo?.zoneamento?.observacao}</td>
                        </tr>
                    </tbody>
                </table>
            </Row>
            <p className="font-semibold">
                LEI DE ZONEAMENTO ► LC 14/2023 | LC23/2022
            </p>
            <Row>
                <table className="table">
                    <tbody>
                        <tr style={{ background: "#D0CECE" }}>
                            {propertyInfo &&
                                propertyInfo.zoneamento &&
                                propertyInfo.zoneamento.questions &&
                                propertyInfo.zoneamento.questions.map(
                                    (allowed) => (
                                        <td key={allowed.id}>
                                            {allowed.texto}
                                        </td>
                                    )
                                )}
                        </tr>
                        <tr>
                            {propertyInfo &&
                                propertyInfo.zoneamento &&
                                propertyInfo.zoneamento.questions &&
                                propertyInfo.zoneamento.questions.map(
                                    (allowed) => (
                                        <td
                                            className="font-semibold"
                                            key={allowed.id}
                                        >
                                            {allowed.valor}
                                        </td>
                                    )
                                )}
                        </tr>
                    </tbody>
                </table>
            </Row>

            <p style={{ pageBreakBefore: "always" }} className="font-semibold">
                TIPOS DE USOS E PERMISSÕES ► LC 14/2023 | LC23/2022
            </p>
            {/* <p>
                <a
                    href="https://d1cge5fflrvk0g.cloudfront.net/palmeira/leis/ANEXO_2.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Para informações detalhadas sobre as definições, clique
                    aqui.
                </a>
            </p> */}
            <Row>
                <table className="table">
                    <tbody>
                        <tr style={{ background: "#D0CECE" }}>
                            <td className="font-semibold">PERMISSIVO</td>
                            <td className="font-semibold">PERMITIDO</td>
                            <td className="font-semibold">PROIBIDO</td>
                        </tr>
                        <tr>
                            {propertyInfo?.zoneamento?.lei_permissivas && (
                                <td>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: propertyInfo?.zoneamento?.lei_permissivas
                                                .trimStart()
                                                .trimEnd(),
                                        }}
                                    />
                                </td>
                            )}
                            {propertyInfo?.zoneamento?.lei_permitidas && (
                                <td>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: propertyInfo?.zoneamento?.lei_permitidas
                                                .trimStart()
                                                .trimEnd(),
                                        }}
                                    />
                                </td>
                            )}
                            {propertyInfo?.zoneamento?.lei_proibidas && (
                                <td>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: propertyInfo?.zoneamento?.lei_proibidas
                                                .trimStart()
                                                .trimEnd(),
                                        }}
                                    />
                                </td>
                            )}
                            {/* <td>
                                {splitted2 &&
                                    splitted2.map((allowed) => (
                                        <>
                                            <div key={allowed}>
                                                {allowed.trimStart().trimEnd()}
                                            </div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: allowed,
                                                }}
                                            />
                                        </>
                                    ))}
                            </td>
                            <td>
                                {splitted &&
                                    splitted.map((allowed) => (
                                        <div key={allowed}>
                                            {allowed.trimStart().trimEnd()}
                                        </div>
                                    ))}
                            </td>
                            <td>
                                {splitted3 &&
                                    splitted3.map((allowed) => (
                                        <div key={allowed}>
                                            {allowed.trimStart().trimEnd()}
                                        </div>
                                    ))}
                            </td> */}
                        </tr>
                    </tbody>
                </table>
            </Row>
        </>
    );
};
