/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactSelect from "react-select";
import { LOCALES } from "../../../../i18n/locales";
import { ClientBrasao } from "../../../../shared/assets/logos";
import { SelectOptions } from "../../../../shared/interfaces/ISelectOptions";
import { Select } from "../../../global";

export const Switcher = (props: {
    handleChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
    currentLocale: string | number | readonly string[] | undefined;
}) => {
    // Languages
    const languages = [
        { name: "English (United States)", code: LOCALES.ENGLISH },
        { name: "Português (Brasil)", code: LOCALES.PORTUGUESE },
        { name: "Español (Latinoamérica)", code: LOCALES.SPANISH },
    ];

    return (
        <header>
            <div className="absolute z-10 py-2 px-2 rounded-lg top-5 right-1/4">
                <div>
                    {/* Language switch dropdown here 
                    <FormattedMessage id="languages" />:{" "} 
                    https://github.com/Ibaslogic/i18n_react_intl_project/blob/main/src/components/App.js
                    https://blog.logrocket.com/react-intl-internationalize-your-react-apps/
                    https://github.com/formatjs/formatjs/issues/465
                    */}

                    <select
                        onChange={props.handleChange}
                        value={props.currentLocale}
                    >
                        {languages.map(({ name, code }) => (
                            <option key={code} value={code}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </header>
    );
};
