import { LOCALES } from "./locales";

export const messages = {
    [LOCALES.PORTUGUESE]: {
        signin: "AUTENTICAR",
        recovery_password: "RECUPERAR SENHA",
        enter: "ENTRAR",
        send_password: "ENVIAR SENHA",
        email: "Email",
        password: "Senha",
        welcome: "Bem Vindo(a)!",
        form_invalid: "Formulário Inválido!",
        forgot_password: "ESQUECEU A SENHA?",
        back: "VOLTAR",
        signout: "SAIR",
        signup: "CADASTRAR",
        create_user: "CADASTRE-SE",
        name: "Nome",
        document: "CPF",
        phone: "Celular",
        confirm_password: "Confirme sua senha",
        group: "Grupo",
        msg_public_access: "Selecione o Grupo ACESSO PÚBLICO para receber um email e ativar sua conta.",
        msg_other_groups_access: "Os demais grupos precisam de aprovação por um usuário habilitado.",
        register: "REGISTRAR",
        update_password: "ATUALIZAR SENHA",
        webgeo_settings: "CONFIGURAÇÕES WEBGEO",
        new_password: "Nova senha",
        current_password: "Senha atual",
        change_password: "ALTERAR SENHA",
        change: "ALTERAR",
        //success
            user_created: "Usuário criado com sucesso!",
            new_user_valid: "Usuário validado com sucesso, redirecionaremos você para página principal em 10s",
            validating_user: "Estamos validando seu usuário, aguarde um momento",
            updated_password: "Senha atualizada com sucesso, redirecionaremos você para página principal em 10s",
        price_display:
          "How {n, number, ::currency/USD} is displayed in your selected language",
        number_display:
          "This is how {n, number} is formatted in the selected locale",
        start_today: "Start Today: {d, date}",
        // menu
        about_project: "About the project",
        contact_us: "Contact us",
        //measure
        erase_measure: "LIMPAR",
        area_measure: "MEDIR ÁREA",
        linear_measure: "MEDIR COMPRIMENTO",
        //bottom menu
        layers: "CAMADAS",
        search: "BUSCAR",
        print_map: "IMPRIMIR MAPA",
        //errors
        page_not_found: "404. Parece que a página que você está tentando acessar não existe.",
        permission_not_granted: "403. Parece que você não possui permissão para a página que você está tentando acessar.",
        languages: "Idioma",
         //grp
         pending_users: "Usuários pendentes de aprovação",
         back_to_webgeo: "Voltar ao Webgeo",
         exit: "Sair",
         home: "INÍCIO",
         webgeo: "WEBGEO",
         projects: "PROJETOS",
         access_groups: "Grupos de acesso",
         Código: "Código",
         Nome: "Nome",
         Grupo: "Grupo",
         "Criado em": "Criado em",
         Ações: "Ações",
         Documento: "Documentos",
         Contatos: "Contatos",
         Ativo: "Ativo",
         "Código reset senha": "Código reset senha",
         "Código de ativação": "Código de ativação",
         "Created at": "Criado em",
         "Atualizado em": "Atualizado em",
         "Nome descritivo no webgeo": "Nome descritivo no webgeo",
         "Nome registrado no geoserver": "Nome registrado no geoserver",
         Estilos: "Estilos",
         Exibições: "Exibições",
         Cache: "Cache",
         Ordem: "Ordem",
         "Nome do estilo": "Nome do estilo",
         "Nome do estilo geoserver": "Nome do estilo geoserver",
         "Camada relacionada": "Camada relacionada",
         Mensagem: "Mensagem",
         Usuário: "Usuário",
         IP: "IP",
         Sistema: "Sistema",
         filtros: "Filtros",
         cadastro: "Cadastro",
         BCI: "BCI",
         "Consulta prévia": "Consulta prévia",
         Login: "Login",
         Bcm: "BCM",
         Webgeo: "Webgeo",
         Confrontante: "Confrontante",
         Progresso: "Progresso",
         Obra: "Obra",
         Prioridade: "Prioridade",
         Tipo: "Tipo",
         Situação: "Situação",
         Prvisão: "Previsão",
         "Horas estimadas": "Horas estimadas",
         Endereço: "Endereço",
         "Criado por": "Criado por",
          //
        Acessos: "Acessos",
        Usuários : "Usuários",
        Grupos: "Grupos",
        "Usuários e restrições": "Usuários e restrições",
        "Configurações webgeo": "Configurações webgeo",
        Camadas: "Camadas",
        "Grupos de camadas": "Grupos de camadas",
        "Estilos de camadas": "Estilos de camadas",
        "Logs de acesso": "Logs de acesso",
        "Nome do grupo": "Nome do grupo",
        previous: "Anterior",
        next: "Próximo",
        items: "{n, number} itens",
        showing: "Exibindo: ", 
        of: " de ",
        FILTRAR: "FILTRAR",
        EDITAR: "EDITAR",
        CADASTRAR: "CADASTRAR",
        success_update_item: "Item atualizado com sucesso",
        success_create_item: "Item criado com sucesso",
        "Grupo de camadas": "Grupo de camadas",
        "Gerenciador de permissões": "Gerenciador de permissões",
        "Configuração de camadas": "Setting up layers",
        "Estilos das camadas": "Estilos das camadas",
        show_more_options: "Mostrar mais",
        Propriedade: "Propriedade",
        "Histórico de atualizações": "Histórico de atualizações",
        "Boletim de cadastro imobiliário": "Boletim de cadastro imobiliário",
        IPTU: "IPTU",
        Leitura: "Leitura",
        Atualização: "Atualização",
        Cadastro: "Cadastro",
        Remoção: "Remoção",
        save: "SALVAR",

    },
  [LOCALES.ENGLISH]: {
        signin: "SIGN IN",
        recovery_password: "RECOVERY PASSWORD",
        enter: "ENTER",
        send_password: "SEND PASSWORD",
        email: "Email",
        password: "Password",
        welcome: "Welcome!",
        form_invalid: "Formulário Inválido!",
        forgot_password: "Forgot password?",
        back: "BACK",
        signout: "Sign Out",
        signup: "SIGN UP",
        create_user: "Create user",
        name: "Name",
        document: "Document",
        phone: "Phone",
        confirm_password: "Confirm your password",
        group: "Group",
        msg_public_access: "Select Public Access to receive an email and activate your account.",
        msg_other_groups_access: "The other groups need to be approved by a qualified user.",
        register: "REGISTER",
        update_password: "UPDATE PASSWORD",
        webgeo_settings: "WEBGEO SETTINGS",
        new_password: "New password",
        current_password: "Password",
        change_password: "CHANGE PASSWORD",
        change: "CHANGE",
        //success
            user_created: "User created successfully!",
            new_user_valid: "User validated successfully, we will redirect you to the main page in 10 seconds",
            validating_user: "We are validating your user, please wait",
            updated_password: "Password updated successfully, we will redirect you to the main page in 10 seconds",
        price_display:"How {n, number, ::currency/USD} is displayed in your selected language",
        number_display:
        "This is how {n, number} is formatted in the selected locale",
        start_today: "Start Today: {d, date}",
        // menu
        about_project: "About the project",
        contact_us: "Contact us",
        //measure
        erase_measure: "CLEAR",
        area_measure: "MEASURE AREA",
        linear_measure: "MEASURE LENTGH",
        //bottom menu
        layers: "LAYERS",
        search: "SEARCH",
        print_map: "PRINT MAP",
        //errors
        page_not_found: "404. URL not found.",
        permission_not_granted: "403. You don't have permission to access this page.",
        languages: "Language",
        //grp
        pending_users: "Users pending approval",
        back_to_webgeo: "Back to Webgeo",
        exit: "Exit",
        home: "HOME",
        webgeo: "WEBGEO",
        projects: "PROJECTS",
        access_groups: "Access groups",
        Código: "Code",
        Nome: "Name",
        Grupo: "Group",
        "Criado em": "Created at",
        Ações: "Actions",
        Documento: "Documents",
        Contatos: "Contacts",
        Ativo: "Active",
        "Código reset senha": "Reset password code",
        "Código de ativação": "Activation code",
        "Created at": "Created at",
        "Atualizado em": "Updated at",
        "Nome descritivo no webgeo": "Registrated name in webgeo (description)",
        "Nome registrado no geoserver": "Registrated name in geoserver",
        Estilos: "Styles",
        Exibições: "Showing in",
        Cache: "Cache",
        Ordem: "Order",
        "Nome do estilo": "Style name",
        "Nome do estilo geoserver": "Style name geoserver",
        "Camada relacionada": "Related layer",
        Mensagem: "Message",
        Usuário: "User",
        IP: "IP",
        Sistema: "System",
        filtros: "Filter",
        cadastro: "Add",
        BCI: "BCI",
        "Consulta prévia": "Consulta prévia",
        Login: "Login",
        Bcm: "BCM",
        Webgeo: "Webgeo",
        Confrontante: "Confrontante",
        Progresso: "Progress",
        Obra: "Construction",
        Prioridade: "Priority",
        Tipo: "Type",
        Situação: "Status",
        Prvisão: "End date",
        "Horas estimadas": "Estimated hours",
        Endereço: "Address",
        "Criado por": "Created by",
         //
         Acessos: "Accesses",
         Usuários : "Users",
         Grupos: "Groups",
         "Usuários e restrições": "Users and prohibitions",
         "Configurações webgeo": "Settings webgeo",
         Camadas: "Layers",
         "Grupos de camadas": "Layers group",
         "Estilos de camadas": "Layers styles",
         "Logs de acesso": "Access logs",
         "Nome do grupo": "Group name",
         previous: "Previous",
         next: "Next",
         items: "{n, number} items",
         showing: "Showing: ",
         of: " of ",
         FILTRAR: "FILTER",
         EDITAR: "EDIT",
         CADASTRAR: "REGISTER",
         success_update_item: "Item updated successfully",
        success_create_item: "Item created successfully",
        "Grupo de camadas": "Layers group",
        "Gerenciador de permissões": "Setting up permissions",
        "Configuração de camadas": "Setting up layers",
        "Estilos das camadas": "Layers styles",
        show_more_options: "More",
        Propriedade: "Properties",
        "Histórico de atualizações": "Update history",
        "Boletim de cadastro imobiliário": "Boletim de cadastro imobiliário",
        IPTU: "IPTU",
        Leitura: "Read",
        Atualização: "Update",
        Cadastro: "Create",
        Remoção: "Remove",
        save: "SAVE",
  },
  [LOCALES.SPANISH]: {
        signin: "INICIAR SESIÓN",
        recovery_password: "RECUPERAR CONTRASEÑA",
        enter: "ENTRAR",
        send_password: "ENVIAR CONTRASEÑA",
        password : "Contraseña",
        email: "Correo electrónico",
        welcome: "¡Te damos la bienvenida!",
        form_invalid: "¡Forma Invalida!",
        forgot_password: "¿OLVIDO LA CONTRASEÑA?",
        back: "VOLVER",
        signout: "CERRAR SESIÓN",
        signup: "CREAR CUENTA",
        create_user: "CREAR CUENTA",
        name: "Nombre",
        document: "Documento",
        phone: "Teléfono móvil",
        confirm_password: "Confirmar la contraseña",
        group: "Grupo",
        msg_public_access: "Seleccione el Grupo ACCESO PUBLICO para recibir un correo electrónico y activar su cuenta.",
        msg_other_groups_access: "Los otros grupos requieren la aprobación de un usuario habilitado.",
        register: "CREAR",
        update_password: "ACTUALIZAR CONTRASEÑA",
        webgeo_settings: "AJUSTES EN WEBGEO",
        new_password: "Neuva contraseña",
        current_password: "Contraseña",
        change_password: "CAMBIAR CONTRASEÑA",
        change: "CAMBIAR",
        //success
            user_created: "¡Cuenta creada con éxito!",
            new_user_valid: "Cuenta activada con éxito, lo redireccionaremos a la página principal en 10 segundos",
            validating_user: "Estamos validando tu cuenta, espera un momento.",
            updated_password: "Contraseña actualizada con éxito, lo redireccionaremos a la página principal en 10 segundos",
        price_display:
        "Comment {n, number, ::currency/USD} $ s'affiche dans la langue sélectionnée",
        number_display:
        "Voici comment {n, number} sont formatés dans les paramètres régionaux sélectionnés ",
        start_today: "Commencez aujourd'hui: {d, date}",
        // menu
        about_project: "À propos du projet",
        contact_us: "Contactez-nous",
        //measure
        erase_measure: "BORRAR",
        area_measure: "MEDIR EL ÁREA",
        linear_measure: "MEDIR LA LONGITUD",
        //bottom menu
        layers: "CAPAS",
        search: "BUSCAR",
        print_map: "IMPRIMIR MAPA",
        //errors
        page_not_found: "404. No se ha encontrado la URL solicitada en este servidor.",
        permission_not_granted: "403. No tienes permiso acceder a esta página.",
        languages: "Lingua",
        //grp
        pending_users: "Cuentas a serem habilitadas",
        back_to_webgeo: "Volver a lo Webgeo",
        exit: "Salir",
        home: "INICIO",
        webgeo: "WEBGEO",
        projects: "PROYETOS",
        access_groups: "Grupos de acceso",
        Código: "Código",
        Nome: "Nombre",
        Grupo: "Grupo",
        "Criado em": "Creado en",
        Ações: "Acciones",
        Documento: "Documento",
        Contatos: "Contatos",
        Ativo: "Activo",
        "Código reset senha": "Codego reset contraseña",
        "Código de ativação": "Codego de ativaccion",
        "Created at": "Creado en",
        "Atualizado em": "Actualizado en",
        "Nome descritivo no webgeo": "Nombre descriptivo en webgeo",
        "Nome registrado no geoserver": "Nombre registrado no lo geoserve",
        Estilos: "Estilos",
        Exibições: "Mostrar",
        Cache: "Cache",
        Ordem: "Orden",
        "Nome do estilo": "Nombre de lo estilo",
        "Nome do estilo geoserver": "Nombre de lo estilo en geoserver",
        "Camada relacionada": "Capa relacionado",
        Mensagem: "Mensaje",
        Usuário: "Cuenta",
        IP: "IP",
        Sistema: "Sistema",
        filtros: "Filtrar",
        cadastro: "Adiccionar",
        BCI: "BCI",
        "Consulta prévia": "Consulta prévia",
        Login: "Login",
        Bcm: "BCM",
        Webgeo: "Webgeo",
        Confrontante: "Confrontante",
        Progresso: "Progreso",
        Obra: "Construcciones",
        Prioridade: "Prioridad",
        Tipo: "Tipo",
        Situação: "Situación",
        Prvisão: "Prognóstico",
        "Horas estimadas": "Horas estimadas",
        Endereço: "Dirección",
        "Criado por": "Creado por",
        //
        Acessos: "Acesos",
        Usuários : "Cuentas",
        Grupos: "Grupos",
        "Usuários e restrições": "Cuentas e prohibiciones",
        "Configurações webgeo": "Ajustes webgeo",
        Camadas: "Capas",
        "Grupos de camadas": "Grupo de capas",
        "Estilos de camadas": "Estilos de capas",
        "Logs de acesso": "Logs de acceso",
        "Nome do grupo": "Nombre del grupo",
        previous: "Anterior",
        next: "Seguinte",
        items: "{n, number} elementos",
        showing: "Mostrando: ", 
        of: " de ",
        FILTRAR: "FILTRAR",
        EDITAR: "ALTERAR",
        CADASTRAR: "REGISTRAR",
        success_update_item: "Item actualizado con êxito",
        success_create_item: "Item creado con êxito",
        "Grupo de camadas": "Grupo de capas",
        "Gerenciador de permissões": "Administrar permisos",
        "Configuração de camadas": "Ajustes de capas",
        "Estilos das camadas": "Estilos de las capas",
        show_more_options: "Mostrar más",
        Propriedade: "Propiedad",
        "Histórico de atualizações": "Historial de actualizaciones",
        "Boletim de cadastro imobiliário": "Boletim de cadastro imobiliário",
        IPTU: "IPTU",
        Leitura: "Leer",
        Atualização: "Actualizar ",
        Cadastro: "Crear",
        Remoção: "Borrar",
        save: "GUARDAR",
  },
};