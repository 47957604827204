import { LOCALES } from "../../../i18n/locales";
import { IEnv } from "../../../shared/interfaces/IEnv";

export default {
    client: {
        name: "loma_plata",
        nomePrefeitura: "Loma Plata",
        logo: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/paraguai_small.png",
        brasao: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/paraguai_small.png",
        municipio: "| LOMA PLATA - PY",
        idioma: LOCALES.SPANISH,
    },
    webgeo: {
        logo: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/logomagnovieira.png",
        coordinatesPattern: "EPSG:32722",
        bci: {
            nomeSecretaria: "Loma Plata",
        },
        confrontante: {
            informacoesRodape: "Loma Plata",
            nomeSecretaria: "Loma Plata",
        },
        consultaPrevia: {
            nomeSecretaria: "Loma Plata",
            contact: {
                email: "xxx",
                endereco: "xxx",
                horarioAtendimento: "xxx",
                telefone: "xxx",
            },
            leiUsoDeSolo: "Loma Plata XXXX/XXXX",
            leiVagasDeEstacionamento: "Loma Plata XXXX/XXXXX",
        },
        config: {
            bci: false,
            confrontante: false,
            consultaPrevia: false,
            iptu: false,
            laws: false,
        },
        map: {
            center: [-59.8368152, -22.3972014],
            zoom: 18,
        },
    },
    api: {
        url: "https://api-prd.semv.com.br/lomaplata/",
    },
    geoserver: {
        url: "https://geoserversp.semv.com.br/geoserver",
    },
} as IEnv;
