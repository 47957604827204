/* eslint-disable react/no-danger */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { IProperty } from "../../../../shared/interfaces/IProperties";

interface Props {
    propertyInfo?: IProperty;
}

export const PalmeiraLeiEstacionamento: React.FC<Props> = ({
    propertyInfo,
}) => {
    // const splitted = propertyInfo?.zoneamento?.lei_permissivas
    //     .toUpperCase()
    //     .split(";");

    // const splitted2 = propertyInfo?.zoneamento?.lei_permitidas
    //     .toUpperCase()
    //     .split(";");

    // const splitted3 = propertyInfo?.zoneamento?.lei_proibidas
    //     .toUpperCase()
    //     .split(";");

    // const splitted4 = propertyInfo?.zoneamento?.lei_estacionamento
    //     .toUpperCase()
    //     .split(";");

    // console.log(splitted2);
    return (
        <>
            <h5 style={{ breakBefore: "always" }}>
                VAGAS PARA ESTACIONAMENTO LC 14/2019 - LC 15/2019 - LC 23/2022
            </h5>

            <table className="table">
                <tbody>
                    <tr>
                        {propertyInfo?.zoneamento?.lei_estacionamento && (
                            <td>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: propertyInfo?.zoneamento?.lei_estacionamento
                                            .trimStart()
                                            .trimEnd(),
                                    }}
                                />
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>
        </>
    );
};
