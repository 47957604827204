import { ClientsEnum } from "../shared/enums/clients.enum";
import { IEnv } from "../shared/interfaces/IEnv";

import PalmeiraDev from "./providers/palmeira/dev.env";
import PalmeiraStaging from "./providers/palmeira/staging.env";
import PalmeiraPrd from "./providers/palmeira/prd.env";

import CastroDev from "./providers/castroOFF/dev.env";
import CastroStaging from "./providers/castroOFF/staging.env";
import CastroPrd from "./providers/castroOFF/prd.env";

import OrtigueiraDev from "./providers/ortigueira/dev.env";
import OrtigueiraStaging from "./providers/ortigueira/staging.env";
import OrtigueiraPrd from "./providers/ortigueira/prd.env";

import ImbauDev from "./providers/imbau/dev.env";
import ImbauStaging from "./providers/imbau/staging.env";
import ImbauPrd from "./providers/imbau/prd.env";

import PiraiDev from "./providers/pirai/dev.env";
import PiraiStaging from "./providers/pirai/staging.env";
import PiraiPrd from "./providers/pirai/prd.env";

import WbDev from "./providers/wb/dev.env";
import WbStaging from "./providers/wb/staging.env";
import WbPrd from "./providers/wb/prd.env";

import JgvDev from "./providers/jgv/dev.env";
import JgvStaging from "./providers/jgv/staging.env";
import JgvPrd from "./providers/jgv/prd.env";

import BomSucessoDev from "./providers/bomsucesso/dev.env";
import BomSucessoStaging from "./providers/bomsucesso/staging.env";
import BomSucessoPrd from "./providers/bomsucesso/prd.env";

import TibagiDev from "./providers/tibagi/dev.env";
import TibagiStaging from "./providers/tibagi/staging.env";
import TibagiPrd from "./providers/tibagi/prd.env";

import JacarezinhoDev from "./providers/jacarezinho/dev.env";
import JacarezinhoStaging from "./providers/jacarezinho/staging.env";
import JacarezinhoPrd from "./providers/jacarezinho/prd.env";

import CarambeiDev from "./providers/carambei/dev.env";
import CarambeiStaging from "./providers/carambei/staging.env";
import CarambeiPrd from "./providers/carambei/prd.env";

import IpirangaDev from "./providers/ipiranga/dev.env";
import IpirangaStaging from "./providers/ipiranga/staging.env";
import IpirangaPrd from "./providers/ipiranga/prd.env";

import NeulandDev from "./providers/neuland/dev.env";
import NeulandStaging from "./providers/neuland/staging.env";
import NeulandPrd from "./providers/neuland/prd.env";

import LomaPlataDev from "./providers/lomaplata/dev.env";
import LomaPlataStaging from "./providers/lomaplata/staging.env";
import LomaPlataPrd from "./providers/lomaplata/prd.env";

import FiladelfiaDev from "./providers/filadelfia/dev.env";
import FiladelfiaStaging from "./providers/filadelfia/staging.env";
import FiladelfiaPrd from "./providers/filadelfia/prd.env";

import MariscalDev from "./providers/mariscal/dev.env";
import MariscalStaging from "./providers/mariscal/staging.env";
import MariscalPrd from "./providers/mariscal/prd.env";

const EnvironmentsProviders: Record<ClientsEnum, Record<string, IEnv>> = {
    [ClientsEnum.PALMEIRA]: {
        dev: PalmeiraDev,
        staging: PalmeiraStaging,
        prd: PalmeiraPrd,
    },
    [ClientsEnum.CASTRO]: {
        dev: CastroDev,
        staging: CastroStaging,
        prd: CastroPrd,
    },
    [ClientsEnum.ORTIGUEIRA]: {
        dev: OrtigueiraDev,
        staging: OrtigueiraStaging,
        prd: OrtigueiraPrd,
    },
    [ClientsEnum.IMBAU]: {
        dev: ImbauDev,
        staging: ImbauStaging,
        prd: ImbauPrd,
    },
    [ClientsEnum.WENCESLAU_BRAZ]: {
        dev: WbDev,
        staging: WbStaging,
        prd: WbPrd,
    },
    [ClientsEnum.JAGUARIAIVA]: {
        dev: JgvDev,
        staging: JgvStaging,
        prd: JgvPrd,
    },
    [ClientsEnum.PIRAI]: {
        dev: PiraiDev,
        staging: PiraiStaging,
        prd: PiraiPrd,
    },
    [ClientsEnum.BOMSUCESSO]: {
        dev: BomSucessoDev,
        staging: BomSucessoStaging,
        prd: BomSucessoPrd,
    },
    [ClientsEnum.TIBAGI]: {
        dev: TibagiDev,
        staging: TibagiStaging,
        prd: TibagiPrd,
    },
    [ClientsEnum.JACAREZINHO]: {
        dev: JacarezinhoDev,
        staging: JacarezinhoStaging,
        prd: JacarezinhoPrd,
    },
    [ClientsEnum.CARAMBEI]: {
        dev: CarambeiDev,
        staging: CarambeiStaging,
        prd: CarambeiPrd,
    },
    [ClientsEnum.IPIRANGA]: {
        dev: IpirangaDev,
        staging: IpirangaStaging,
        prd: IpirangaPrd,
    },
    [ClientsEnum.NEULAND]: {
        dev: NeulandDev,
        staging: NeulandStaging,
        prd: NeulandPrd,
    },
    [ClientsEnum.NEULAND]: {
        dev: NeulandDev,
        staging: NeulandStaging,
        prd: NeulandPrd,
    },
    [ClientsEnum.LOMAPLATA]: {
        dev: LomaPlataDev,
        staging: LomaPlataStaging,
        prd: LomaPlataPrd,
    },
    [ClientsEnum.FILADELFIA]: {
        dev: FiladelfiaDev,
        staging: FiladelfiaStaging,
        prd: FiladelfiaPrd,
    },
    [ClientsEnum.MARISCAL]: {
        dev: MariscalDev,
        staging: MariscalStaging,
        prd: MariscalPrd,
    },
};

export default EnvironmentsProviders[
    process.env.REACT_APP_CLIENT as ClientsEnum
][process.env.REACT_APP_ENV as string];
