import React from "react";
import { FormattedMessage } from "react-intl";
import environments from "../../../environments";

import { WebgeoLogo } from "../../../shared/assets/logos";

export const NotFound: React.FC = () => {
    return (
        <div className="w-screen h-screen flex items-center justify-center flex-column">
            <img src={environments.webgeo.logo || WebgeoLogo} alt="Logo semv" />
            <div>
                <h5 className="text-teal-900">
                    <FormattedMessage id="page_not_found" />
                </h5>
            </div>
        </div>
    );
};
