import React, { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowLeftSquare } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton, Select } from "../../../../../global";
import { Input } from "../../../../../global/input/index";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import {
    ICemeteryWorkers,
    ICemeteryWorkersCompany,
    ICemeteryWorkersResponse,
} from "../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";

const labelStyle = { fontSize: "15px", color: "#234e52" };

interface EditFormProps {
    doAfterSubmit(subscription: string): void;
    backButton(): void;
    workersInfo?: Partial<ICemeteryWorkersResponse>;
}
export const EditWorkers: React.FC<EditFormProps> = ({
    doAfterSubmit,
    backButton,
    workersInfo,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [serviceType, setServiceType] = useState<"PROPRIETÁRIO" | "EMPRESA">(
        "PROPRIETÁRIO"
    );

    const id = workersInfo?.id;
    const cadastramento = new Date().toISOString();

    const handleSubmitOwner = async ({
        tiposervicoForm,
        cadastramentoForm,
        nomecolaboradorForm,
        cpfcolaboradorForm,
        telefonecolaboradorForm,
        emailcolaboradorForm,
        funcaocolaboradorForm,
    }: {
        tiposervicoForm: string;
        cadastramentoForm: string;
        nomecolaboradorForm: string;
        cpfcolaboradorForm: string;
        telefonecolaboradorForm: string;
        emailcolaboradorForm: string;
        funcaocolaboradorForm: string;
    }) => {
        const payload: ICemeteryWorkers = {
            tipo_servico: tiposervicoForm,
            data_cadastramento: cadastramentoForm,
            tipo_prestador: serviceType,
            nome_colaborador: nomecolaboradorForm,
            cpf_colaborador: cpfcolaboradorForm,
            telefone_colaborador: telefonecolaboradorForm,
            email_colaborador: emailcolaboradorForm,
            funcao_colaborador: funcaocolaboradorForm,
            inscricao: workersInfo?.inscricao || "",
        };
        try {
            setLoading(true);
            if (id) {
                await CemeteryInfoApiService.updateWorkers(id, payload);

                showToast({
                    type: "success",
                    message:
                        "Dados dos prestadores foram inseridos com sucesso",
                });

                doAfterSubmit(workersInfo?.inscricao as string);
            }
        } catch (err) {
            ApiErrorHandler(err);
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitCompany = async ({
        tiposervicoForm,
        cadastramentoForm,
        nomecolaboradorForm,
        cpfcolaboradorForm,
        telefonecolaboradorForm,
        emailcolaboradorForm,
        funcaocolaboradorForm,
        responsavelempresaForm,
        documentoempresaForm,
        razaosocialempresaForm,
        tiposervicoempresaForm,
        telefoneempresaForm,
        emailempresaForm,
    }: {
        tiposervicoForm: string;
        cadastramentoForm: string;
        nomecolaboradorForm: string;
        cpfcolaboradorForm: string;
        telefonecolaboradorForm: string;
        emailcolaboradorForm: string;
        funcaocolaboradorForm: string;
        responsavelempresaForm: string;
        documentoempresaForm: string;
        razaosocialempresaForm: string;
        tiposervicoempresaForm: string;
        telefoneempresaForm: string;
        emailempresaForm: string;
    }) => {
        const payload: ICemeteryWorkersCompany = {
            tipo_servico: tiposervicoForm,
            data_cadastramento: cadastramentoForm,
            tipo_prestador: serviceType,
            nome_colaborador: nomecolaboradorForm,
            cpf_colaborador: cpfcolaboradorForm,
            telefone_colaborador: telefonecolaboradorForm,
            email_colaborador: emailcolaboradorForm,
            funcao_colaborador: funcaocolaboradorForm,
            responsavel_empresa: responsavelempresaForm,
            documento_empresa: documentoempresaForm,
            razao_social_empresa: razaosocialempresaForm,
            tipo_servico_empresa: tiposervicoempresaForm,
            telefone_empresa: telefoneempresaForm,
            email_empresa: emailempresaForm,
            inscricao: workersInfo?.inscricao || "",
        };
        try {
            setLoading(true);
            if (id) {
                await CemeteryInfoApiService.updateWorkers(id, payload);

                showToast({
                    type: "success",
                    message:
                        "Dados dos prestadores foram inseridos com sucesso",
                });

                doAfterSubmit(workersInfo?.inscricao as string);
            }
        } catch (err) {
            ApiErrorHandler(err);
        } finally {
            setLoading(false);
        }
    };

    const handleRelatedByChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setServiceType(event.target.value as "PROPRIETÁRIO" | "EMPRESA");
    };

    useEffect(() => {
        if (workersInfo?.tipo_prestador) {
            setServiceType(
                workersInfo.tipo_prestador as "PROPRIETÁRIO" | "EMPRESA"
            );
        }
    }, []);

    const handleGoBack = () => {
        backButton();
    };

    return (
        <div className="bg-gray-50">
            <div className="grid grid-cols-3 grid-rows-1">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>VOLTAR</Tooltip>}
                >
                    <div className="mb-3 flex space-x-2 justify-center">
                        <button type="button" onClick={handleGoBack}>
                            <BsArrowLeftSquare color="teal" size={20} />
                        </button>
                    </div>
                </OverlayTrigger>
            </div>
            {!loading && (
                <div className="flex space-x-2 ml-2">
                    <p style={labelStyle}>Tipo do Prestador:</p>
                    <FormCheck
                        type="radio"
                        name="serviceTypeOwner"
                        label="PROPRIETÁRIO"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="PROPRIETÁRIO"
                        checked={serviceType === "PROPRIETÁRIO"}
                        disabled
                    />
                    <FormCheck
                        type="radio"
                        name="serviceTypeCompany"
                        label="EMPRESA"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="EMPRESA"
                        checked={serviceType === "EMPRESA"}
                        disabled
                    />
                </div>
            )}
            {serviceType === "PROPRIETÁRIO" && (
                <Form
                    onSubmit={handleSubmitOwner}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                        <Select
                            name="tiposervicoForm"
                            label="TIPO DO SERVIÇO"
                            defaultValue={{
                                label: workersInfo?.tipo_servico,
                                value: workersInfo?.tipo_servico,
                            }}
                            options={[
                                {
                                    label: "CONSTRUÇÃO",
                                    value: "CONSTRUÇÃO",
                                },
                                {
                                    label: "MANUTENÇÃO",
                                    value: "MANUTENÇÃO",
                                },
                                {
                                    label: "LIMPEZA",
                                    value: "LIMPEZA",
                                },
                            ]}
                        />

                        <Input
                            name="nomecolaboradorForm"
                            label="NOME DO COLABORADOR"
                            defaultValue={workersInfo?.nome_colaborador}
                        />
                        <Input
                            name="cpfcolaboradorForm"
                            label="CPF DO COLABORADOR"
                            defaultValue={workersInfo?.cpf_colaborador}
                        />
                        <Select
                            name="funcaocolaboradorForm"
                            label="FUNÇÃO DO COLABORADOR"
                            defaultValue={{
                                label: workersInfo?.funcao_colaborador,
                                value: workersInfo?.funcao_colaborador,
                            }}
                            options={[
                                {
                                    label: "PEDREIRO(A)",
                                    value: "PEDREIRO(A)",
                                },
                                {
                                    label: "FAXINEIRO(A)",
                                    value: "FAXINEIRO(A)",
                                },
                                {
                                    label: "AUXILIAR GERAL",
                                    value: "AUXILIAR GERAL",
                                },
                                {
                                    label: "SERVENTE",
                                    value: "SERVENTE",
                                },
                                {
                                    label: "ELETRICISTA",
                                    value: "ELETRICISTA",
                                },
                            ]}
                        />
                        <Input
                            name="telefonecolaboradorForm"
                            label="TELEFONE DO COLABORADOR"
                            defaultValue={workersInfo?.telefone_colaborador}
                        />
                        <Input
                            name="emailcolaboradorForm"
                            label="EMAIL DO COLABORADOR"
                            defaultValue={workersInfo?.email_colaborador}
                        />

                        <Input
                            name="cadastramentoForm"
                            label="DATA DE CADASTRAMENTO"
                            defaultValue={cadastramento}
                            disabled
                            hidden
                        />
                    </div>
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">
                                <FormattedMessage id="save" />
                            </MainButton>
                        </div>
                    </div>
                </Form>
            )}
            {serviceType === "EMPRESA" && (
                <Form
                    onSubmit={handleSubmitCompany}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                        <Input
                            name="responsavelempresaForm"
                            label="NOME DO RESPONSÁVEL"
                            defaultValue={workersInfo?.responsavel_empresa}
                            disabled
                        />
                        <Input
                            name="documentoempresaForm"
                            label="CPF/CNPJ"
                            defaultValue={workersInfo?.documento_empresa}
                            disabled
                        />
                        <Input
                            name="razaosocialempresaForm"
                            label="RAZÃO SOCIAL"
                            defaultValue={workersInfo?.razao_social_empresa}
                            disabled
                        />
                        <Select
                            name="tiposervicoempresaForm"
                            label="TIPO DO SERVIÇO"
                            defaultValue={{
                                label: workersInfo?.tipo_servico,
                                value: workersInfo?.tipo_servico,
                            }}
                        />
                        <Input
                            name="telefoneempresaForm"
                            label="TELEFONE DA EMPRESA"
                            defaultValue={workersInfo?.telefone_empresa}
                            disabled
                        />
                        <Input
                            name="emailempresaForm"
                            label="EMAIL DA EMPRESA"
                            defaultValue={workersInfo?.email_empresa}
                            disabled
                        />
                        <hr />
                        <hr />
                        <Select
                            name="funcaocolaboradorForm"
                            label="FUNÇÃO DO COLABORADOR"
                            defaultValue={{
                                label: workersInfo?.funcao_colaborador,
                                value: workersInfo?.funcao_colaborador,
                            }}
                            options={[
                                {
                                    label: "PEDREIRO(A)",
                                    value: "PEDREIRO(A)",
                                },
                                {
                                    label: "FAXINEIRO(A)",
                                    value: "FAXINEIRO(A)",
                                },
                                {
                                    label: "AUXILIAR GERAL",
                                    value: "AUXILIAR GERAL",
                                },
                                {
                                    label: "SERVENTE",
                                    value: "SERVENTE",
                                },
                                {
                                    label: "ELETRICISTA",
                                    value: "ELETRICISTA",
                                },
                            ]}
                        />
                        <Select
                            name="tiposervicoForm"
                            label="TIPO DO SERVIÇO"
                            defaultValue={{
                                label: workersInfo?.tipo_servico,
                                value: workersInfo?.tipo_servico,
                            }}
                            options={[
                                {
                                    label: "CONSTRUÇÃO",
                                    value: "CONSTRUÇÃO",
                                },
                                {
                                    label: "MANUTENÇÃO",
                                    value: "MANUTENÇÃO",
                                },
                                {
                                    label: "LIMPEZA",
                                    value: "LIMPEZA",
                                },
                            ]}
                        />
                        <Input
                            name="nomecolaboradorForm"
                            label="NOME DO COLABORADOR"
                            defaultValue={workersInfo?.nome_colaborador}
                        />
                        <Input
                            name="cpfcolaboradorForm"
                            label="CPF DO COLABORADOR"
                            defaultValue={workersInfo?.cpf_colaborador}
                        />
                        <Input
                            name="telefonecolaboradorForm"
                            label="TELEFONE DO COLABORADOR"
                            defaultValue={workersInfo?.telefone_colaborador}
                        />
                        <Input
                            name="emailcolaboradorForm"
                            label="EMAIL DO COLABORADOR"
                            defaultValue={workersInfo?.email_colaborador}
                        />
                        <Input
                            name="cadastramentoForm"
                            label="DATA DE CADASTRAMENTO"
                            defaultValue={cadastramento}
                            disabled
                            hidden
                        />
                    </div>
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">
                                <FormattedMessage id="save" />
                            </MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
