import React, { useCallback, useState } from "react";
import {
    Col,
    Form,
    FormGroup,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {
    BsArrowLeftSquare,
    BsPencilSquare,
    BsPlusSquare,
    BsPrinter,
    BsTrash,
} from "react-icons/bs";
import { MdOutlineExpandMore } from "react-icons/md";
import { FeaturesEnum } from "../../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../../shared/hooks/useAuth";
import { ICemeteryDeceasedResponse } from "../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { AddDeceased } from "./add";
import { EditDeceased } from "./edit";
import { ReadDeceased } from "./read";
import { Popup } from "../../../../../global/popup";

interface Props {
    doAfterSubmit(subscription: string): void;
    info?: Partial<ICemeteryDeceasedResponse[]>;
    infoLayer?: Record<string, string>;
}

export const Deceased: React.FC<Props> = ({
    doAfterSubmit,
    info,
    infoLayer,
}) => {
    const { user } = useAuth();
    const [enableCreate, setEnableCreate] = useState<boolean>(false);
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [beingEditted, setBeingEdited] = useState<number>(0);
    const [currentDeceased, setCurrentDeceased] =
        useState<ICemeteryDeceasedResponse>();
    const [popup, setPopup] = useState<{
        show: boolean;
        id: number | undefined;
    }>();

    const handleEnableEdit = (id: number) => {
        setBeingEdited(id);
        setEnableEdit(!enableEdit);
        setLoading(!loading);
    };
    const handleEnableCreate = () => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
    };

    const handleAfterCreateSubmit = (subscription: string) => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleAfterEditSubmit = (subscription: string) => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleDelete = async (id: number | undefined) => {
        if (id) {
            setPopup({
                show: true,
                id,
            });
        }
    };

    const handleDeleteCancel = () => {
        setPopup({
            show: false,
            id: undefined,
        });
    };

    const handleDeleteConfirm = useCallback(async () => {
        if (popup && popup.show && popup.id) {
            try {
                if (popup.id) {
                    setLoading(true);

                    await CemeteryInfoApiService.deleteDeceased(popup.id);

                    showToast({
                        message: `As informações do falecido foram deletadas com sucesso`,
                        type: "success",
                    });

                    doAfterSubmit(infoLayer?.inscricao as string);
                }
            } catch (err) {
                ApiErrorHandler(err, "warn");
            } finally {
                setLoading(false);
            }
        }
        setPopup({
            show: false,
            id: undefined,
        });
    }, [popup]);

    return (
        <>
            {!enableEdit && !currentDeceased && (
                <div className="grid grid-cols-3 grid-rows-1">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {enableCreate ? "VOLTAR" : "ADICIONAR FALECIDO"}
                            </Tooltip>
                        }
                    >
                        <div className="mb-3 flex space-x-2 justify-center">
                            {user.authorizationsByFeatureName[
                                FeaturesEnum.CEMETERY_DECEASED
                            ]?.canCreate && (
                                <button
                                    type="button"
                                    onClick={handleEnableCreate}
                                >
                                    {enableCreate ? (
                                        <BsArrowLeftSquare
                                            color="teal"
                                            size={20}
                                        />
                                    ) : (
                                        <BsPlusSquare color="teal" size={20} />
                                    )}
                                </button>
                            )}
                        </div>
                    </OverlayTrigger>
                </div>
            )}
            {enableCreate && (
                <AddDeceased
                    // deceasedInfo={info}
                    control={infoLayer?.controle}
                    subscription={infoLayer?.inscricao}
                    doAfterSubmit={handleAfterCreateSubmit}
                />
            )}
            {enableEdit && info && (
                <EditDeceased
                    deceasedInfo={info[beingEditted]}
                    doAfterSubmit={handleAfterEditSubmit}
                    backButton={() => setEnableEdit(false)}
                />
            )}
            {currentDeceased && (
                <ReadDeceased
                    deceasedInfo={currentDeceased}
                    backButton={() => setCurrentDeceased(undefined)}
                />
            )}
            {popup && popup.show && (
                <Popup
                    phrase="Tem certeza que deseja apagar o falecido?"
                    show
                    handleConfirm={handleDeleteConfirm}
                    handleCancel={handleDeleteCancel}
                />
            )}
            {!info && <>Sem informações cadastradas.</>}
            {!enableCreate &&
                !enableEdit &&
                !currentDeceased &&
                info?.map((deceased, index) => (
                    <FormGroup key={deceased?.id}>
                        <Row
                            style={{
                                margin: "1rem",
                                padding: "1rem",
                                border: "2px solid teal",
                                borderRadius: "1rem",
                            }}
                        >
                            {deceased?.em_translado && (
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        TRANSLADO INTERNO ?
                                    </Form.Label>
                                    <Form.Control value="SIM" disabled />
                                </Col>
                            )}
                            {deceased?.translado_externo && (
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        TRANSLADO DE ENVIO ?
                                    </Form.Label>
                                    <Form.Control value="SIM" disabled />
                                </Col>
                            )}
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    FALECIDO
                                </Form.Label>
                                <Form.Control value={deceased?.nome} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TIPO DE CADASTRO
                                </Form.Label>
                                <Form.Control
                                    value={deceased?.tipo_cadastramento}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    AÇÕES
                                </Form.Label>
                                <div className="mb-3 flex space-x-2">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                {enableCreate
                                                    ? "VOLTAR"
                                                    : "EXPANDIR"}
                                            </Tooltip>
                                        }
                                    >
                                        <div className="mb-3 flex space-x-2 justify-center">
                                            {user.authorizationsByFeatureName[
                                                FeaturesEnum.CEMETERY_DECEASED
                                            ]?.canRead && (
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setCurrentDeceased(
                                                            deceased
                                                        )
                                                    }
                                                >
                                                    <MdOutlineExpandMore
                                                        color="teal"
                                                        size={20}
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    </OverlayTrigger>

                                    {!deceased?.translado_externo && (
                                        <>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {enableCreate
                                                            ? "VOLTAR"
                                                            : "EDITAR FALECIDO"}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="mb-3 flex space-x-2 justify-center">
                                                    {user
                                                        .authorizationsByFeatureName[
                                                        FeaturesEnum
                                                            .CEMETERY_DECEASED
                                                    ]?.canUpdate && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                handleEnableEdit(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <BsPencilSquare
                                                                color="teal"
                                                                size={20}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {enableCreate
                                                            ? "VOLTAR"
                                                            : "APAGAR FALECIDO"}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="mb-3 flex space-x-2 justify-center">
                                                    {user
                                                        .authorizationsByFeatureName[
                                                        FeaturesEnum
                                                            .CEMETERY_DECEASED
                                                    ]?.canRemove && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                handleDelete(
                                                                    deceased?.id
                                                                )
                                                            }
                                                        >
                                                            <BsTrash
                                                                color="red"
                                                                size={20}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        AUTORIZAÇÃO DE ABERTURA
                                                        DE TÚMULO
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="mb-3 flex space-x-2 justify-center">
                                                    {user
                                                        .authorizationsByFeatureName[
                                                        FeaturesEnum
                                                            .ABERTURA_TUMULO
                                                    ]?.canRead && (
                                                        <a
                                                            href={`/autorizacao-abertura-tumulo/${deceased?.inscricao}/${deceased?.id}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <BsPrinter
                                                                color="teal"
                                                                size={20}
                                                            />
                                                        </a>
                                                    )}
                                                </div>
                                            </OverlayTrigger>
                                            {deceased?.tipo_cadastramento ===
                                                "TRANSLADO RECEBIMENTO" && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            DECLARAÇÃO DE
                                                            RECEBIMENTO
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="mb-3 flex space-x-2 justify-center">
                                                        {user
                                                            .authorizationsByFeatureName[
                                                            FeaturesEnum
                                                                .TRANSLADO_EXTERNO
                                                        ]?.canRead && (
                                                            <a
                                                                href={`/translado-externo/${deceased?.inscricao}/${deceased?.inscricao_falecido}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <BsPrinter
                                                                    color="teal"
                                                                    size={20}
                                                                />
                                                            </a>
                                                        )}
                                                    </div>
                                                </OverlayTrigger>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                ))}
        </>
    );
};
