import React, { useCallback, useEffect, useState } from "react";
import { Form } from "@unform/web";

import { MainButton, Modal, Pagination, LocalLoading } from "../../../global";
import { SearchInputsCemetery } from "./search-inputs-cemetery";
import { formatSingleFilter } from "../../../../shared/utils/fields.utils";
import { CemeteryInfoApiService } from "../../../../shared/services/api/cemetery-api.service";
import { SearchCemeteryTable } from "./components/table";
import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";
import { ICemeteryDeceasedResponse } from "../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { SearchCemeteryDeceasedTable } from "./components/table-deceased";
import { SearchCemeteryLoteTable } from "./components/table-lote";
import { showToast } from "../../../global/toast";

interface SearchFormProps {
    inputs: string[];
}

const LIMIT = 20;

export const SearchFormCemetery: React.FC<SearchFormProps> = ({ inputs }) => {
    const [localLoading, setLocalLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [properties, setProperties] = useState<Partial<ICemeteryResponse>[]>(
        []
    );
    const [deceasedList, setDeceasedList] = useState<
        Partial<ICemeteryDeceasedResponse>[]
    >([]);
    const [filters, setFilters] = useState<Record<string, string>>();
    const [propertiesAmount, setPropertiesAmount] = useState<number>(0);
    const [searchType, setSearchType] = useState<
        "OWNER" | "DECEASED" | "LOTE"
    >();
    const [loteList, setLoteList] = useState<Partial<ICemeteryResponse>[]>([]);

    const toggleModalState = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const fechProperties = useCallback(
        async (page: number, form: Record<string, string> | undefined) => {
            const where = form ? formatSingleFilter(form) : undefined;
            if (where && where.message) {
                showToast({
                    message: `A busca com múltiplos campos ainda não é permitida!`,
                    type: "error",
                });
            } else {
                const query = `offset=${
                    (page - 1) * LIMIT
                }&limit=${LIMIT}&where=${JSON.stringify(where)}`;

                if (searchType === "LOTE") {
                    const graveResponse =
                        await CemeteryInfoApiService.listCemeteryInfoPropertiesByGrave(
                            query,
                            ["geom"]
                        );

                    setPropertiesAmount(graveResponse.paginate.total);

                    setLoteList(graveResponse.data || []);
                }
                if (searchType === "DECEASED") {
                    const deceasedResponse =
                        await CemeteryInfoApiService.deceasedList(query);

                    setPropertiesAmount(deceasedResponse.paginate.total);

                    setDeceasedList(deceasedResponse.data || []);
                }
                if (searchType === "OWNER") {
                    const propertiesResponse =
                        await CemeteryInfoApiService.listCemeteryInfoProperties(
                            query,
                            ["geom"]
                        );

                    setPropertiesAmount(propertiesResponse.paginate.total);

                    setProperties(propertiesResponse.data || []);
                }
            }
        },
        [searchType]
    );

    const handleQueryPaginate = async (page: number) => {
        try {
            setLocalLoading(true);
            await fechProperties(page, filters);
        } finally {
            setLocalLoading(false);
        }
    };

    const doIfSearchTypeChange = useCallback(
        async (page: number) => {
            try {
                setLocalLoading(true);

                await fechProperties(page, filters);

                toggleModalState();
            } finally {
                setLocalLoading(false);
            }
        },
        [fechProperties, filters, toggleModalState]
    );

    const handleSearchType = useCallback((form: Record<string, string>) => {
        try {
            setLocalLoading(true);
            setFilters(form);

            const where = form ? formatSingleFilter(form) : undefined;

            if (where) {
                if (where.message) {
                    showToast({
                        message: `A busca cemitério com múltiplos campos ainda não é permitida!`,
                        type: "error",
                    });
                    return;
                }
                if (Object.hasOwn(where, "nome")) {
                    setSearchType("DECEASED");
                } else if (
                    Object.hasOwn(where, "controle") ||
                    Object.hasOwn(where, "inscricao")
                ) {
                    setSearchType("LOTE");
                } else {
                    setSearchType("OWNER");
                }
            }
        } finally {
            setLocalLoading(false);
        }
    }, []);

    useEffect(() => {
        if (searchType) {
            doIfSearchTypeChange(1);
        }
    }, [searchType, filters]);

    return (
        <>
            <Modal
                show={showModal}
                handleCloseModal={toggleModalState}
                size="xl"
                title="BUSCA CEMITÉRIO"
            >
                <div className="h-96">
                    {localLoading && <LocalLoading />}
                    {properties && searchType === "OWNER" && (
                        <SearchCemeteryTable
                            properties={properties}
                            doAfterActionsClick={toggleModalState}
                        />
                    )}
                    {deceasedList && searchType === "DECEASED" && (
                        <SearchCemeteryDeceasedTable
                            deceaseds={deceasedList}
                            doAfterActionsClick={toggleModalState}
                        />
                    )}
                    {loteList && searchType === "LOTE" && (
                        <SearchCemeteryLoteTable
                            properties={loteList}
                            doAfterActionsClick={toggleModalState}
                        />
                    )}
                </div>

                <Pagination
                    totalOfPages={Math.ceil(propertiesAmount / LIMIT)}
                    doAfterClick={handleQueryPaginate}
                    totalOfItems={propertiesAmount}
                    maxItemsToDisplay={LIMIT}
                />
            </Modal>
            <Form onSubmit={handleSearchType}>
                {inputs.map((input) => (
                    <SearchInputsCemetery input={input} key={input} />
                ))}

                <MainButton type="submit">BUSCAR</MainButton>
            </Form>
        </>
    );
};
