import { LOCALES } from "../../../i18n/locales";
import { IEnv } from "../../../shared/interfaces/IEnv";

export default {
    client: {
        name: "neuland",
        nomePrefeitura: "Colonia Neuland",
        logo: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/paraguai_small.png",
        brasao: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/paraguai_small.png",
        municipio: "| NEULAND - PY",
        idioma: LOCALES.SPANISH,
    },
    webgeo: {
        logo: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/logomagnovieira.png",
        coordinatesPattern: "EPSG:32722",
        bci: {
            nomeSecretaria: "Colonia Neuland",
        },
        confrontante: {
            informacoesRodape: "Colonia Neuland",
            nomeSecretaria: "Colonia Neuland",
        },
        consultaPrevia: {
            nomeSecretaria: "Colonia Neuland",
            contact: {
                email: "xxx",
                endereco: "xxx",
                horarioAtendimento: "xxx",
                telefone: "xxx",
            },
            leiUsoDeSolo:
                "PARÂMETROS DE USO DO SOLO - Anexo (X) - Lei no XXXX/XXXX",
            leiVagasDeEstacionamento:
                "VAGAS PARA ESTACIONAMENTO anexo à lei XXXX/XXXXX",
        },
        config: {
            bci: false,
            confrontante: false,
            consultaPrevia: false,
            iptu: false,
            laws: false,
        },
        map: {
            center: [-60.1393017, -22.6505095],
            zoom: 18,
        },
    },
    api: {
        url: "https://api-staging.semv.com.br/",
    },
    geoserver: {
        url: "https://geoserversp.semv.com.br/geoserver",
    },
} as IEnv;
