import React from "react";

export const DisclaimerPalmeira: React.FC = () => {
    return (
        <div>
            <div className="text-center">
                <h3>CONSULTA PRÉVIA PARA VIABILIDADE DE CONSTRUÇÃO</h3>
                <p>
                    Esta CONSULTA PRÉVIA tem por finalidade apresentar as
                    informações urbanísticas e dados básicos do lote para a
                    emissão de ALVARÁ DE LICENÇA para edificações e
                    parcelamentos, conforme leis urbanas vigentes
                </p>
            </div>
            <div>
                <ul className="pl-0 list-none list-outside">
                    <li>
                        ► INFORMAÇÕES PRESTADAS PELO PODER EXECUTIVO PODENDO
                        HAVER DIVERGENCIAS COM ORGÃOS, AUTARQUIAS OU ENTIDADES
                        PÚBLICAS
                    </li>
                    <li>
                        ► IMAGEM GEORREFENCIADA EM 28/11/2021. APÓS ESTA DATA, É
                        DE RESPONSABILIDAE DO PROPRIETÁRIO MANTER ATUALIZADO SEU
                        CADASTRO
                    </li>
                    <li>
                        ► MAIORES INFORMAÇÕES PODERÃO SER OBTIDAS{" "}
                        <a
                            href="https://d1cge5fflrvk0g.cloudfront.net/palmeira/leis/ANEXO_1.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            NO ANEXO 1
                        </a>{" "}
                        E{" "}
                        <a
                            href="https://d1cge5fflrvk0g.cloudfront.net/palmeira/leis/ANEXO_2.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            NO ANEXO 2
                        </a>{" "}
                        DESTA CONSULTA
                    </li>
                    <li>
                        ► ESTE DOCUMENTO NÃO SUBSTITUI O DOCUMENTO DE ALVARÁ
                    </li>
                </ul>
            </div>
            <div>
                <h5>ALERTAS INCIDENTES SOBRE O LOTE</h5>
            </div>
            <div>
                <ul className="pl-0 list-none list-outside">
                    <li>
                        ► SE O LOTE FOR PARCIALMENTE ATINGIDO POR ÁREA DE
                        PRESERVAÇÃO PERMANENTE - AVERBAR EM MATRÍCULA
                    </li>
                    <li>
                        ► O ZONEAMENTO PRINCIPAL DO LOTE É O QUE O ATINGE EM
                        MAIS DE 50% DE SUA ÁREA, CONFORME GEORREFERENCIAMENTO
                    </li>
                    <li>
                        ► OS DADOS CONSTANTES DO CADASTRO IMOBILIÁRIO NÃO
                        DIVERGEM COM A SITUAÇÃO DO TERRENO ATÉ A DATA{" "}
                        {new Date(Date.now()).toLocaleDateString("pt-BR")}
                    </li>
                    <li>
                        ► DIVERGÊNCIAS DE MEDIDAS APRESENTADAS NESTA CONSULTA E
                        O REGISTRO DE IMÓVEIS DEVERÃO SER READEQUADAS OU
                        RETIFICADAS
                    </li>
                </ul>
            </div>
        </div>
    );
};
