import React from "react";

import { ILayers } from "../../../../shared/interfaces/ILayers";
import { IWeGeoLegends } from "../../../../shared/interfaces/ILegends";
import {
    IProperty,
    IPropertyGeom,
} from "../../../../shared/interfaces/IProperties";
import { Croqui } from "../../bci/croquis/map";
import { ConsultaPreviaMapContainer } from "../palmeira/style";

interface ZoneamentoProps {
    propertyInfo?: IProperty;
    propertyGeom?: IPropertyGeom;
    layers?: ILayers[];
    legends?: IWeGeoLegends[];
}

export const ZoneamentoPalmeira: React.FC<ZoneamentoProps> = ({
    propertyInfo,
    propertyGeom,
    layers,
    legends,
}) => {
    if (propertyInfo?.zoneamento?.zona === "SIM") {
        const greenLegends = legends?.find(
            (element) => element.name === "SEAV"
        );
        // console.log(greenLegends);
    }

    const usefulLegends = legends?.find(
        (element) => element.name === propertyInfo?.zoneamento?.zona_uso
    );
    // console.log(usefulLegends);
    return (
        <div className="d-flex">
            {propertyInfo?.inscricaoimobiliaria && propertyGeom && layers && (
                <div>
                    {/* <p className="text-center leading-8">MAPA DO TERRENO</p> */}
                    <ConsultaPreviaMapContainer>
                        <Croqui
                            layers={layers}
                            propertyGeom={propertyGeom}
                            padding={[120, 120, 120, 120]}
                        />
                    </ConsultaPreviaMapContainer>
                </div>
            )}

            <table
                className="table-fixed border-l-4 border-white"
                style={{ background: "#E7E6E6" }}
            >
                <thead>
                    <tr>
                        <th
                            style={{ fontSize: "12pt" }}
                            className="text-center bg-white font-thin"
                            colSpan={2}
                        >
                            DADOS DO TERRENO
                        </th>
                    </tr>
                </thead>
                <tbody className="h-1">
                    {propertyInfo?.inscricaoimobiliaria && (
                        <tr className="border-b-2 border-black border-dotted">
                            <td>INSCRIÇÃO ►</td>
                            <td className="font-semibold">
                                {propertyInfo?.inscricaoimobiliaria}
                            </td>
                        </tr>
                    )}
                    {propertyInfo?.cadastro && (
                        <tr className="border-b-2 border-black border-dotted">
                            <td>CAD. IMOBILIÁRIO ►</td>
                            <td className="font-semibold">
                                {propertyInfo?.cadastro}
                            </td>
                        </tr>
                    )}
                    {propertyInfo?.matricula && (
                        <tr className="border-b-2 border-black border-dotted">
                            <td>MATRÍCULA ►</td>
                            <td className="font-semibold">
                                {propertyInfo?.matricula}
                            </td>
                        </tr>
                    )}
                    {propertyInfo?.zoneamento && (
                        <tr className="border-b-4 border-white">
                            <td>ZONEAMENTO ►</td>
                            <td className="font-semibold">
                                {propertyInfo?.zoneamento?.zona_uso.toUpperCase()}
                            </td>
                        </tr>
                    )}
                    {propertyInfo?.bairronome && (
                        <>
                            <tr className="border-b-2 border-black border-dotted">
                                <td>BAIRRO ▼</td>
                            </tr>
                            <tr className="border-b-2 border-black border-dotted">
                                <td className="font-semibold" colSpan={2}>
                                    {propertyInfo?.bairronome}
                                </td>
                            </tr>
                        </>
                    )}
                    {propertyInfo?.bairronome && (
                        <>
                            <tr className="border-b-2 border-black border-dotted">
                                <td colSpan={2}>LOTEAMENTO ▼</td>
                            </tr>
                            <tr className="border-b-2 border-black border-dotted">
                                <td className="font-semibold" colSpan={2}>
                                    -
                                </td>
                            </tr>
                        </>
                    )}

                    <tr className="border-b-4 border-white">
                        <td>
                            QUADRA ►{" "}
                            <span className="font-semibold">
                                {propertyInfo?.quadra}
                            </span>
                        </td>
                        <td>
                            LOTE ►{" "}
                            <span className="font-semibold">
                                {propertyInfo?.lote}
                            </span>
                        </td>
                    </tr>

                    <tr className="border-b-2 border-black border-dotted">
                        <td colSpan={2}>POSIÇÃO DO LOTE ►</td>
                    </tr>
                    {propertyInfo?.logradouronome && (
                        <>
                            <tr className="border-b-2 border-black border-dotted">
                                <td>ENDEREÇO PRINCIPAL ▼</td>
                                <td>CD ► -</td>
                            </tr>
                            <tr className="border-b-2 border-black border-dotted">
                                <td className="font-semibold" colSpan={2}>
                                    {propertyInfo?.logradouronome}
                                </td>
                            </tr>
                        </>
                    )}
                    <tr className="border-b-2 border-black border-dotted">
                        <td>END. SECUNDÁRIO ▼ </td>
                        <td>CD ► -</td>
                    </tr>
                    <tr className="border-b-2 border-black border-dotted">
                        <td className="font-semibold" colSpan={2}>
                            -
                        </td>
                    </tr>
                    <tr className="border-b-2 border-black border-dotted">
                        <td>EDIFICAÇÃO ►</td>
                        <td>-</td>
                    </tr>
                    {propertyInfo?.logradouronumero && (
                        <tr className="border-b-2 border-black border-dotted">
                            <td>Nº PREDIAL ►</td>
                            <td className="font-semibold">
                                {propertyInfo?.logradouronumero}
                            </td>
                        </tr>
                    )}
                    {propertyInfo?.zoneamento?.zona && (
                        <tr>
                            <td>ÁREA (APP) ►</td>
                            <td
                                className="font-semibold"
                                style={{ color: "#ff0000" }}
                            >
                                {propertyInfo?.zoneamento?.zona}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
