import React from "react";
import { ICemeteryDeceasedResponse } from "../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { SearchFormAction } from "./actions";
import { SearchFormActionDeceased } from "./actions-deceased";

interface PropertiesTableProps {
    doAfterActionsClick?(): void;
    deceaseds: Partial<ICemeteryDeceasedResponse>[];
}

const HIDDEN_TEXT = "*************";

export const SearchCemeteryDeceasedTable: React.FC<PropertiesTableProps> = ({
    doAfterActionsClick,
    deceaseds,
}) => {
    return (
        <div className="flex flex-col h-full w-full">
            <div className="overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-4">
                <div className="inline-block min-w-full">
                    <div className="overflow-hidden">
                        <table className="min-w-full table-auto border-teal-900">
                            <thead className="border-b">
                                <tr className="bg-gray-100">
                                    <th
                                        scope="col"
                                        className="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                                    >
                                        Ações
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                                    >
                                        FALECIDO
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                                    >
                                        CONTROLE
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {deceaseds.map((deceased, index) => (
                                    <tr
                                        key={deceased.id}
                                        className={`${
                                            index % 2 !== 0
                                                ? "bg-gray-100 border-b"
                                                : "bg-white border-b"
                                        }`}
                                    >
                                        <td className="px-6 whitespace-nowrap text-sm font-medium text-gray-900 ">
                                            <div className="flex space-x-3.5">
                                                <SearchFormActionDeceased
                                                    doAfterClick={
                                                        doAfterActionsClick
                                                    }
                                                    deceased={deceased}
                                                />
                                            </div>
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {deceased.nome || HIDDEN_TEXT}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {deceased.controle || HIDDEN_TEXT}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
