import { LOCALES } from "../../../i18n/locales";
import { IEnv } from "../../../shared/interfaces/IEnv";

export default {
    client: {
        name: "mariscal",
        nomePrefeitura: "Mariscal José Félix Estigarribia",
        logo: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/paraguai_small.png",
        brasao: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/paraguai_small.png",
        municipio: "| MARISCAL JOSÉ FELIX ESTIGARRIBIA - PY",
        idioma: LOCALES.SPANISH,
    },
    webgeo: {
        logo: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/logomagnovieira.png",
        coordinatesPattern: "EPSG:32722",
        bci: {
            nomeSecretaria: "Mariscal José Félix Estigarribia",
        },
        confrontante: {
            informacoesRodape: "Mariscal José Félix Estigarribia",
            nomeSecretaria: "Mariscal José Félix Estigarribia",
        },
        consultaPrevia: {
            nomeSecretaria: "Mariscal José Félix Estigarribia",
            contact: {
                email: "xxx",
                endereco: "xxx",
                horarioAtendimento: "xxx",
                telefone: "xxx",
            },
            leiUsoDeSolo: "Mariscal José Félix Estigarribia XXXX/XXXX",
            leiVagasDeEstacionamento:
                "Mariscal José Félix Estigarribia XXXX/XXXXX",
        },
        config: {
            bci: false,
            confrontante: false,
            consultaPrevia: false,
            iptu: false,
            laws: false,
        },
        map: {
            center: [-60.6000769, -22.0298774],
            zoom: 18,
        },
    },
    api: {
        url: "http://localhost:3330",
    },
    geoserver: {
        url: "https://geoserversp.semv.com.br/geoserver",
    },
} as IEnv;
