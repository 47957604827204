import React, { ChangeEvent, useState } from "react";
import "./css/index.css";

import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { Routes } from "./routes";
import { ToastAnimated } from "./components/global/toast";
import { LoadingProvider } from "./components/global/loading";
import { AuthProvider } from "./shared/contexts/auth.context";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import environments from "./environments";
import { Switcher } from "./components/page-releated/home/switcher";

const App: React.FC = () => {
    const locale = environments.client.idioma || LOCALES.PORTUGUESE;

    const [currentLocale, setCurrentLocale] = useState(locale);

    const handleChange = (e: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setCurrentLocale(e.target.value);
        // storing locale in the localstorage
        // localStorage.setItem("locale", e.target.value);
    };

    const getInitialLocal = () => {
        // getting stored items
        const savedLocale = localStorage.getItem("locale");
        return savedLocale || LOCALES.ENGLISH;
    };

    return (
        <IntlProvider
            messages={messages[currentLocale]}
            locale={currentLocale}
            defaultLocale={LOCALES.PORTUGUESE}
            onError={(err) => {
                if (err.code === "MISSING_TRANSLATION") {
                    // console.warn("Missing translation", err.message);
                    return;
                }
                throw err;
            }}
        >
            <AuthProvider>
                <LoadingProvider>
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </LoadingProvider>
            </AuthProvider>
            <ToastAnimated />
            <Switcher
                currentLocale={currentLocale}
                handleChange={handleChange}
            />
        </IntlProvider>
    );
};

export default App;
