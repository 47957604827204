import { LOCALES } from "../../../i18n/locales";
import { IEnv } from "../../../shared/interfaces/IEnv";

export default {
    client: {
        name: "filadelfia",
        nomePrefeitura: "Filadelfia",
        logo: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/paraguai_small.png",
        brasao: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/paraguai_small.png",
        municipio: "| FILADELFIA - PY",
        idioma: LOCALES.SPANISH,
    },
    webgeo: {
        logo: "https://d1cge5fflrvk0g.cloudfront.net/logo_webgeo/logomagnovieira.png",
        coordinatesPattern: "EPSG:32722",
        bci: {
            nomeSecretaria: "Filadelfia",
        },
        confrontante: {
            informacoesRodape: "Filadelfia",
            nomeSecretaria: "Filadelfia",
        },
        consultaPrevia: {
            nomeSecretaria: "Filadelfia",
            contact: {
                email: "xxx",
                endereco: "xxx",
                horarioAtendimento: "xxx",
                telefone: "xxx",
            },
            leiUsoDeSolo: "Filadelfia XXXX/XXXX",
            leiVagasDeEstacionamento: "Filadelfia XXXX/XXXXX",
        },
        config: {
            bci: false,
            confrontante: false,
            consultaPrevia: false,
            iptu: false,
            laws: false,
        },
        map: {
            center: [-60.0257659, -22.3609196],
            zoom: 18,
        },
    },
    api: {
        url: "https://api-prd.semv.com.br/filadelfia/",
    },
    geoserver: {
        url: "https://geoserversp.semv.com.br/geoserver",
    },
} as IEnv;
