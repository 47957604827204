import { useState, useEffect, useCallback } from "react";
import { createFilter } from "react-select";
import { SelectOptions } from "../../../../../../../shared/interfaces/ISelectOptions";
import { ICemeteryDeceasedResponse } from "../../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../../../shared/services/api/cemetery-api.service";
import { Select } from "../../../../../../global";

interface Props {
    subscription: string;
}

export const DeceasedComponent: React.FC<Props> = ({ subscription }) => {
    const [options, setOptions] = useState<SelectOptions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const convertOptions = async (opts: ICemeteryDeceasedResponse[]) => {
        const selectOptions: SelectOptions[] = [];

        opts.forEach((element: ICemeteryDeceasedResponse) => {
            const option: SelectOptions = {
                label: element.nome,
                value: element.id,
            };
            selectOptions.push(option);
        });
        return selectOptions;
    };

    const fetchData = useCallback(async (subscription_: string) => {
        setLoading(true);
        try {
            const response = await CemeteryInfoApiService.getDeceased(
                subscription_
            );
            const op = await convertOptions(response.data);
            setOptions(op);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData(subscription);
    }, [fetchData, subscription]);

    return (
        <div>
            {!loading && (
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                        ignoreCase: true,
                    })}
                    name="deceasedForm"
                    label="Falecidos"
                    options={options}
                    placeholder="Selecione o falecido..."
                />
            )}
        </div>
    );
};
