import React from "react";

import { Tooltip as TooltipBoostratp, OverlayTrigger } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface TooltipProps {
    children: React.ReactElement;
    text: string | React.ReactNode;
    placement?: "top" | "right" | "bottom" | "left";
}

export const Tooltip: React.FC<TooltipProps> = ({
    children,
    text,
    placement = "top",
}) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                text && typeof text === "string" ? (
                    <TooltipBoostratp>
                        <FormattedMessage id={text.toString()} />
                    </TooltipBoostratp>
                ) : (
                    <TooltipBoostratp>{text}</TooltipBoostratp>
                )
            }
        >
            {children}
        </OverlayTrigger>
    );
};
