import React, { useCallback, useEffect, useState } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";
import { CemeteryInfoApiService } from "../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../shared/utils/errors.utils";
import { CemiteryActions } from "./actions";
import { AttachmentsCemetery } from "./tabs/attachments/attachments";
import { Deceased } from "./tabs/deceased/deceased";
import { Grave } from "./tabs/grave/grave";
import { Owner } from "./tabs/owner/owner";
import { TombstonePhotos } from "./tabs/photos/photos";
import { RegisterSubComponents } from "./tabs/register-sub-components";
import { Taxes } from "./tabs/taxes/taxes";
import { Transferred } from "./tabs/transferred/transferred";
import { Workers } from "./tabs/workers/workers";

interface CemeteryProps {
    info: Record<string, string>;
}

export const Cemetery: React.FC<CemeteryProps> = ({ info }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [cemeteryInfo, setCemeteryInfo] = useState<ICemeteryResponse>();

    const fetchCemeteryInfo = useCallback(async (subscription: string) => {
        try {
            setLoading(true);

            const fetchedInfo = await CemeteryInfoApiService.getCemeteryInfo(
                subscription,
                [
                    "taxes",
                    "workers",
                    "deceased",
                    "owner",
                    "transferred",
                    "attachments",
                ]
            );

            setCemeteryInfo(fetchedInfo.data[0]);
        } catch (err) {
            ApiErrorHandler(err, "warn");
        } finally {
            setLoading(false);
        }
    }, []);

    const doAfterSubmitEdit = () => {
        fetchCemeteryInfo(info.inscricao);
    };

    useEffect(() => {
        if (info.inscricao.length > 0) {
            fetchCemeteryInfo(info.inscricao);
        }
    }, [info.inscricao, fetchCemeteryInfo]);

    return (
        <>
            <div className="mb-5 flex space-x-10 justify-center">
                {cemeteryInfo && (
                    <CemiteryActions cemetery={cemeteryInfo} iconsSize={30} />
                )}
            </div>

            <Tab.Container defaultActiveKey="grave">
                <Row>
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item as="button">
                                <Nav.Link eventKey="grave">JAZIGO</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="button">
                                <Nav.Link eventKey="owner">
                                    PROPRIETÁRIO
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="button">
                                <Nav.Link eventKey="deceased">
                                    FALECIDO(S)
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="button">
                                <Nav.Link eventKey="helpers">
                                    PRESTADORES
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="button">
                                <Nav.Link eventKey="taxes">TAXAS</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="button">
                                <Nav.Link eventKey="tombstone_photo">
                                    FOTO DE FACHADA
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="button">
                                <Nav.Link eventKey="attachment">
                                    ANEXOS
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="button">
                                <Nav.Link eventKey="transfer">
                                    TRANSLADO
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="button">
                                <Nav.Link eventKey="register-sub-components">
                                    CADASTRAMENTO
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col>
                        <Tab.Content
                            style={{
                                height: "540px",
                                margin: 0,
                                overflowX: "hidden",
                                overflowY: "auto",
                            }}
                        >
                            <Tab.Pane eventKey="owner">
                                <Owner
                                    info={cemeteryInfo?.owner}
                                    infoLayer={info}
                                    doAfterSubmit={doAfterSubmitEdit}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="deceased">
                                <Deceased
                                    info={cemeteryInfo?.deceased}
                                    infoLayer={info}
                                    doAfterSubmit={doAfterSubmitEdit}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="grave">
                                <Grave
                                    info={cemeteryInfo}
                                    infoLayer={info}
                                    doAfterSubmit={doAfterSubmitEdit}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="helpers">
                                <Workers
                                    info={cemeteryInfo?.workers}
                                    infoLayer={info}
                                    doAfterSubmit={doAfterSubmitEdit}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="taxes">
                                <Taxes
                                    info={cemeteryInfo?.taxes}
                                    infoLayer={info}
                                    doAfterSubmit={doAfterSubmitEdit}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tombstone_photo">
                                <TombstonePhotos
                                    files={
                                        // eslint-disable-next-line no-nested-ternary
                                        Array.isArray(info.cdn_url)
                                            ? info.cdn_url
                                            : info.cdn_url
                                            ? [info.cdn_url]
                                            : undefined
                                    }
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="attachment">
                                <AttachmentsCemetery
                                    infoLayer={info}
                                    info={cemeteryInfo?.attachments}
                                    doAfterSubmit={doAfterSubmitEdit}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="transfer">
                                <Transferred
                                    info={cemeteryInfo?.transferred}
                                    infoLayer={info}
                                    doAfterSubmit={doAfterSubmitEdit}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="register-sub-components">
                                <RegisterSubComponents />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
};
