import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CityInfo = styled.div`
    display: flex;
    padding-top: 50px;
    flex-direction: column;
`;

export const LeftHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PropertyFirstInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
`;

export const ConsultaPreviaStyle = createGlobalStyle`
    .container {
        font-family:  'Arial Narrow';
        font-weight: lighter;
    }
    h3 {
        font-size: 18pt;
        line-height: 0.86cm;
        background-color: #D0CECE;
        font-weight: 400;
    }
    h5 {
        font-size: 15pt;
        line-height: 0.76cm;
        background-color: #D0CECE;
        font-weight: 400;
    }
    p {
        margin-left: 0.5cm;
        font-size: 14pt;
        line-height: 0.66cm;
        font-weight: lighter;
    }
    li {
        font-size: 12pt;
        line-height: 0.66cm;
        font-weight: lighter;
    }
    td {
        font-size: 12pt;
        line-height: 0.66cm;
        font-weight: lighter;
    }
@media print {
    @page {
        margin-left: 2.5cm;
        // margin-top: 0.5cm;
        // margin-bottom: 1.5cm;
        font-weight: lighter;
    }
    .container {
        margin-left: 0;
        // min-width: 350px;
        max-width: 1000vh;
        font-family:  'Arial Narrow';
        font-weight: lighter;
    }
    p {
        margin-left: 0.5cm;
        font-size: 10pt;
        font-weight: lighter;
    }
    h3 {
        font-size: 12pt;
        background-color: #D0CECE;
        font-weight: 600;
    }
    h5 {
        font-size: 10pt;
        background-color: #D0CECE;
        font-weight: 600;
    }
    li {
        font-size: 8pt;
        font-weight: lighter;
    }
    td {
        font-size: 12pt;
        font-weight: lighter;
    }
}`;
